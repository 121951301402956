import React, {useEffect, useState} from "react";
import {motion} from "framer-motion";

import {animateMotion} from "../utils/motion-animate";
import Header from "../containers/header";
import Menu from "../containers/menu";
import About from "../containers/about";
import Testimonials from "../containers/testimonials";
import Intro from "../containers/intro";
import {Tooltip} from "react-tooltip";
import Process from "../containers/process";
import Technologies from "../containers/technologies";
import Contacts from "../containers/contacts";
import Footer from "../containers/footer";

export default function Home() {
	const [showToTop, setShowToTop] = useState(false);

	useEffect(() => {
		window.addEventListener('scroll', handleScroll);
	}, []);

	const handleScroll = () => {
		if (window.scrollY > 100) {
			setShowToTop(true);
		} else {
			setShowToTop(false);
		}
	};

	return (
		<>
			<div>
				<motion.button
					initial={"hidden"}
					variants={animateMotion({
						direction: "vertical",
						distance: 40,
						delay: 0.5,
					})}
					whileInView={showToTop ? "show" : "hidden"}
					className="fixed z-50 p-4 bg-red-600 rounded-full text-white btn-top"
					onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}
					whileHover={{scale: 1.1}}
				>
					<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none"
					     viewBox="0 0 24 24" stroke="currentColor">
						<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
						      d="M5 10l7-7m0 0l7 7m-7-7v18"/>
					</svg>
				</motion.button>
			</div>
			{/* HEADER */}
			<Header/>

			{/* MENU */}
			<Menu/>

			{/* CONTENT */}
			<div className="relative flex flex-auto overflow-hidden bg-home pt-14 mb-4 content"
			     style={{
				     borderRadius: "40px",
				     transform: "none",
				     transformOrigin: "50% 50% 0px"
			     }}
			>
				<div className="relative isolate flex w-full flex-col pt-9">
					<main className="w-full flex-auto">
						<div className="background-gradient">
							<motion.div
								initial={"hidden"}
								variants={animateMotion({
									direction: "vertical",
									distance: 40,
								})}
								whileInView={"show"}
								viewport={{once: true}}
								className="absolute left-0 right-[-8rem] top-20 z-20 hidden md:flex"
							>
								<div className="mx-auto w-full h-svh max-w-7xl bg-astronauta"></div>
							</motion.div>
							<Intro/>

							<Technologies/>
							<Tooltip
								className="transition-all"
								id="my-tooltip"
								style={{backgroundColor: "#ef4444", color: "#FFFFFF"}}
							/>

							<About/>
						</div>

						<Testimonials/>
						<Process/>

						<Contacts/>
					</main>
					<Footer/>
				</div>
			</div>
		</>
	);
}
