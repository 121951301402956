import React, {useState} from "react";
import {type Control, Controller, type FieldError} from "react-hook-form";
import TextArea from "./textarea";
import InputText from "./text";
import InputMask from "./mask";

interface InputsProps {
	control: Control<any>;
	name: string;
	label: string;
	required?: boolean;
	input?: "text" | "textarea" | "mask";
	type?: "text" | "email" | "phone";
	mask?: string | (string | RegExp)[];
	className?: string;
	inputClass?: string;
}

const Inputs = ({
	                control,
	                name,
	                required = false,
	                label,
	                type = "text",
	                input = "text",
	                mask,
	                className,
	                inputClass
                }: InputsProps) => {
	const [showError, setShowError] = useState<FieldError>();

	return (
		<div className={`form-item ${className}`}>
			<Controller
				control={control}
				name={name}
				render={({
					         field: {onChange, onBlur, value},
					         fieldState: {isTouched, error}
				         }) => {
					if (isTouched) setShowError(error);

					switch (input) {
						case "textarea":
							return (<TextArea name={name} value={value} onChange={onChange} className={inputClass} onBlur={onBlur}/>)
						case "mask":
							if (mask) {
								return (<InputMask name={name} value={value} onBlur={onBlur} onChange={onChange} type={type} mask={mask}
								                   className={inputClass}/>)
							} else {
								return (<InputText name={name} value={value} onChange={onChange} type={type} className={inputClass}
								                   onBlur={onBlur}/>)
							}
						default:
							return (<InputText name={name} value={value} onChange={onChange} type={type} className={inputClass}
							                   onBlur={onBlur}/>)
					}
				}}/>
			<label htmlFor={name}>
				{label}{required && "*"}
				{showError?.message && <span className="error-label">{showError.message}</span>}
			</label>
		</div>
	)
}

export default Inputs;