export const scroll = (id: string) => {
	const section = document.querySelector(id);
	section?.scrollIntoView( { behavior: 'smooth', block: 'start' } );

};

export const shuffleArrays = (array: any[]) => array
	.map(value => ({ value, sort: Math.random() }))
	.sort((a, b) => a.sort - b.sort)
	.map(({ value }) => value)

export const isMobile = () => {
	if (typeof window !== "undefined") {
		return window.innerWidth < 768;
	}
	return false;
}