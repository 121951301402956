import React, {useEffect} from 'react';
import {motion} from "framer-motion";
import {animateMotion} from "../utils/motion-animate";
import useConfig from "../context/config-context";
import {Icon} from "./index";
import {isMobile} from "../utils/configs";

export interface FlashMessageProps {
	message: string;
	type: "success" | "warning" | "error" | "info";
	time?: number;
	show: boolean;
}

const listStyle = [
	{
		type: "success",
		icon: "checkmark1",
		color: "green-400",
	},
	{
		type: "warning",
		icon: "exclamation",
		color: "orange-400"
	},
	{
		type: "error",
		icon: "cross1",
		color: "red-400"
	},
	{
		type: "info",
		icon: "info1",
		color: "blue-400"
	}
]

let closeTimer: any = null;

const FlashMessage = () => {
	const {toggleMessage, message} = useConfig();

	useEffect(() => {
		if (message) {
			closeTimer = setTimeout(() => {
				toggleMessage({
					...message,
					show: false
				});
			}, 5000);
		}

		return () => clearTimeout(closeTimer);
	}, [message]);

	const findIcon = listStyle.find((icon) => icon.type === message.type);
	const findColor = listStyle.find((icon) => icon.type === message.type);

	return (
		<div className="w-full flex justify-center">
			<motion.div
				initial={"hidden"}
				variants={animateMotion({
					direction: "vertical",
					distance: isMobile() ? -40 : 40,
					delay: 0.2,
					duration: 0.3
				})}
				whileInView={message.show ? "show" : "hidden"}
				exit={"hidden"}
				onClick={() => toggleMessage({
					message: "",
					type: "info",
					time: 5000,
					show: false
				})}
				className={`flex transition fixed z-50 p-4 bg-green-400 flash-message text-white type-message color-${message.type} items-center`}
			>
				<>
					<div className="flex w-1/12 mr-4">
						<div className="flex rounded-full bg-white w-10 h-10 justify-center items-center">
							<Icon
								icon={findIcon?.icon || "info"}
								size={24}
								className={`fill-${findColor?.color}` || "fill-blue-400"}
							/>
						</div>
					</div>
					<div className="w-auto message-text">{message.message}</div>
				</>
			</motion.div>
		</div>
	)
}

export default FlashMessage;