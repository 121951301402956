import React, {useEffect, useRef} from "react";
import Typed from "typed.js";

const Intro = () => {
	const el = useRef(null);

	useEffect(() => {
		const typed = new Typed(el.current, {
			strings: ["criativos", "inovadores", "comprometidos", "analíticos", "competitivos", "apaixonados", "profissionais"],
			typeSpeed: 50,
			loop: true,
			loopCount: Infinity,
			cursorChar: "|",
			backDelay: 3000,
			backSpeed: 25,
		});

		return () => {
			typed.destroy();
		};
	}, []);

	return (<div className="mx-auto max-w-7xl px-6 lg:px-8 mt-36 sm:mt-20 md:pt-48 md:mb-36">
		<div className="mx-auto max-w-2xl lg:max-w-none">
			<div className="max-w-3xl">
				<div className="min-h-lines md:min-h-line">
					<h1
						className="font-display text-5xl font-medium tracking-tight text-neutral-950 [text-wrap:balance] sm:text-7xl">
						Somos <span className="bg-red-600 px-3" ref={el}/>
					</h1>
				</div>
				<p className="mt-6 text-xl text-neutral-600">
					Nossa equipe é o coração pulsante da inovação. Somos profissionais apaixonados
					e criativos, que combinam análise rigorosa com pensamento inovador. <br />Comprometidos com a excelência e
					impulsionados pela competitividade, transformamos desafios em oportunidades, criando soluções que
					definem o futuro de nossos projetos.
				</p>
			</div>
		</div>
	</div>);
}

export default Intro;