import React from 'react';

interface InputTextProps {
	onChange: any;
	onBlur: any;
	value: string;
	name: string;
	type: string;
	className?: string;
}

const InputText = ({onChange, onBlur, type, value, name, className}: InputTextProps) => {
	return (<input
		onChange={onChange}
		onBlur={onBlur}
		value={value}
		name={name}
		type={type}
		id={name}
		className={`hover:ring-4 active:ring-4 ${className}`}
	/>)
}

export default InputText;