import React from 'react';
import { createBrowserRouter } from 'react-router-dom';

import Home from './pages/Home';
import Teste from './pages/Teste';

const router = createBrowserRouter([
	// { path: "/contact/:id", element: <Teste /> },
	{ path: "*", element: <Home /> },
]);

export default router;