import React from 'react';
import {motion} from "framer-motion";
import {scroll} from "../utils/configs";
import useConfig from "../context/config-context";

const Header = () => {
	const {toggleMenu} = useConfig();

	return (
		<div className="absolute left-0 right-0 top-2 z-40 pt-14">
			<div className="mx-auto max-w-7xl px-6 lg:px-8">
				<div className="mx-auto max-w-2xl lg:max-w-none">
					<div className="flex items-center justify-between">
						<motion.a
							initial={{opacity: 0, x: -50}}
							animate={{opacity: 1, x: 0}}
							transition={{
								ease: "linear",
								duration: 0.5,
								y: {duration: 0.5},
							}}
							aria-label="Home"
							href="/"
							className="rocket-logo-pos"
							title="Rocket IT"
						/>
						<motion.div
							initial={{opacity: 0, x: 50}}
							animate={{opacity: 1, x: 0}}
							transition={{
								ease: "linear",
								duration: 0.5,
								y: {duration: 0.5},
							}}
							className="flex items-center gap-x-8"
						>
							<a
								className="rounded-full px-4 py-2 text-sm font-semibold transition bg-neutral-950 text-white hover:bg-neutral-800 cursor-pointer"
								onClick={() => scroll("#contact")}
							>
								<span className="relative">Contatos</span>
							</a>
							<button
								type="button"
								aria-expanded="false"
								aria-controls=":rf:"
								className="group -m-2.5 rounded-full p-2.5 transition hover:bg-neutral-950/10"
								aria-label="Toggle navigation"
								onClick={toggleMenu}
							>
								<svg
									viewBox="0 0 24 24"
									aria-hidden="true"
									className="h-6 w-6 fill-neutral-950 group-hover:fill-neutral-700"
								>
									<path d="M2 6h20v2H2zM2 16h20v2H2z"></path>
								</svg>
							</button>
						</motion.div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Header;