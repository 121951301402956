import React from 'react';
import {useForm} from "react-hook-form"
import * as Yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import clsx from "clsx";
import axios from "axios";

import {Icon} from "../components";
import FlashMessage from "../components/flash-message";
import useConfig from "../context/config-context";
import {validateEmail} from "../utils/validators";
import Inputs from "../components/Inputs/inputs";

const listValues = [
	{
		id: "less-five",
		value: 'Menos de R$ 5 mil',
		check: false
	},
	{
		id: "five-fifteen",
		value: 'R$ 5mil – R$ 15 mil',
		check: true
	},
	{
		id: "fifteen-twenty-five",
		value: 'R$ 15 mil – R$ 25 mil',
		check: false
	},
	{
		id: "twenty-five-fifty",
		value: 'R$ 25 mil – R$ 50mil',
		check: false
	},
	{
		id: "fifty-hundred",
		value: 'R$ 50 mil – R$ 100 mil',
		check: false
	},
	{
		id: "hundred-more",
		value: 'Mais de R$ 100 mil',
		check: false
	}
]

interface FormValues {
	name: string;
	email: string;
	message: string;
	phone?: string;
	company?: string;
	projectValue?: string;
}

export const loginFormSchema = Yup.object({
	name: Yup.string().required("Nome é obrigatório").min(3, "Nome deve ter no mínimo 3 caracteres"),
	email: Yup.string()
		.required("Email é obrigatório")
		.test("email", "E-mail inválido", (value) => validateEmail(value ?? ""))
		.email("E-mail inválido"),
	message: Yup.string().required("Mensagem é obrigatória").min(25, "Você poderia fornecer mais detalhes?"),
});


const Contacts = () => {
	const {toggleMessage} = useConfig();
	const {
		register,
		handleSubmit,
		reset,
		watch,
		control,
		formState: {errors, isValid},
	} = useForm<FormValues>({
		resolver: yupResolver(loginFormSchema),
		reValidateMode: "onChange",
		mode: "onChange",
		defaultValues: {
			email: "",
			name: "",
			message: "",
			phone: "",
			company: "",
		},
	});

	const fieldName = watch("name");
	const fieldEmail = watch("email");
	const fieldMessage = watch("message");

	const onSubmit = async (data: FormValues) => {
		console.log("DATA::::: ", data);
		const cors = {
			headers: {
				'Access-Control-Allow-Origin': '*',
				'Content-Type': 'application/json'
			}
		}
		try {
			const result = await axios.post('https://rocketit.com.br/api/send-contact.php', data, cors);
			reset({
				email: "",
				name: "",
				message: "",
				phone: "",
				company: "",
			});
			toggleMessage({
				message: "Mensagem enviada com sucesso!",
				type: "success",
				time: 5000,
				show: true
			});
			console.log("RESULT::::: ", result);
		}	catch (error) {
			toggleMessage({
				message: "Erro ao enviar mensagem, tente novamente mais tarde.",
				type: "warning",
				time: 5000,
				show: true
			});
			console.log("ERROR::::: ", error);
		}
	}

	const showMessageError = errors.name?.message || errors.email?.message || errors.message?.message;
	const validateField = (field: string) => {
		switch (field) {
			case "name":
				return !errors.name && fieldName.length > 0;
			case "email":
				return !errors.email && fieldEmail.length > 0;
			case "message":
				return !errors.message && fieldMessage.length > 0;
			default:
				return false;
		}
	}

	return (
		<div
			className="mx-auto mt-24 bg-neutral-950 py-12 w-full"
			id="contact"
		>
			<div className="mx-auto max-w-7xl px-6 lg:px-8">
				<div className="mx-auto max-w-2xl lg:max-w-none">
					<div
						className="flex items-center gap-x-8"
						style={{
							opacity: 1,
							transform: "none",
						}}
					>
						<div className="flex-auto">
							<div className="flex items-center gap-x-8">
								<h2
									className="text-center font-display text-2xl font-semibold tracking-wider text-white sm:text-left">
									Como <span className="text-red-600">podemos ajudar</span>?
								</h2>
								<div className="h-px flex-auto bg-neutral-800"></div>
							</div>
							<div className="grid grid-cols-1 gap-2 sm:grid-cols-2">
								<div>
									<p className="w-full mt-6 text-base text-neutral-300 mb-4">
										Estamos aqui para você. Entre em contato agora e receba uma resposta rápida. <br/> Seu projeto é
										nossa prioridade.
									</p>
									<div className="mt-8">
										<span className="font-semibold text-white">Contatos</span>
										<address className="not-italic text-neutral-300 mt-1">
											e-mail: <a href="mailto:Contato<contato@rocketit.com.br>" target="_blank">
											contato@rocketit.com.br</a> <br/>
											telefone / whatsapp: <a href="tel:+5515997987632" target="_blank">+55 (15) 99798-7632</a>
										</address>
									</div>

									<div className="mt-8">
										<p className="flex-row w-full mt-6 text-base text-neutral-300 mb-1 items-center">
											<span className="flex sm:inline mr-4">Se preferir, entre em contato pelo nosso</span>
											<a
												target="_blank"
												className="flex sm:inline w-36 rounded-full ml-0 sm:ml-4 mt-4 sm:mt-0 px-4 py-2 font-semibold transition bg-white text-neutral-950 hover:bg-red-600 hover:text-white cursor-pointer justify-center text-center"
												href="https://api.whatsapp.com/send?phone=5515997987632&text=Ol%C3%A1,%20gostaria%20de%20conversar%20sobre%20meu%20projeto."
											>
												Whatsapp
											</a>
										</p>
									</div>
									<div className="sm:border-l sm:border-transparent mt-8">
										<h2 className="font-display text-base font-semibold text-white">
											Nossas redes
										</h2>
										<ul className="flex gap-6 text-white mt-6">
											<li>
												<a
													aria-label="Facebook"
													className="transition hover:text-neutral-200"
													href="https://www.linkedin.com/company/69529664"
													target="_blank"
												>
													<Icon
														icon="linkedin"
														size={24}
														className="fill-neutral-200"
													/>
												</a>
											</li>
											<li>
												<a
													aria-label="Instagram"
													className="transition hover:text-neutral-200"
													href="https://www.instagram.com/rkt.it"
													target="_blank"
												>
													<Icon
														icon="instagram"
														size={24}
														className="fill-neutral-200"
													/>
												</a>
											</li>
											<li>
												<a
													aria-label="GitHub"
													className="transition hover:text-neutral-200"
													href="https://github.com/rktit"
													target="_blank"
												>
													<Icon
														icon="github"
														size={24}
														className="fill-neutral-200"
													/>
												</a>
											</li>
										</ul>
									</div>
									<div className="astronaut mt-0 sm:mt-24"/>
								</div>
								<div>
									<p className="w-full mt-12 sm:mt-6 text-base text-neutral-300 mb-4">
										Ou, você pode preencher nosso formulário para que possamos entender melhor suas necessidades. <br/>
										Apenas os campos: <strong>
										<span className={clsx("transition", {
											"text-red-600 italic": !validateField("name"),
											"text-white not-italic": validateField("name")
										})}>Nome</span>, <span className={clsx("transition", {
										"text-red-600 italic": !validateField("email"),
										"text-white not-italic": validateField("email")
									})}>E-mail</span> e <span className={clsx("transition", {
										"text-red-600 italic": !validateField("message"),
										"text-white not-italic": validateField("message")
									})}>Mensagem</span>
									</strong> são obrigatórios.
									</p>
									<form className="form">
										<Inputs control={control} name="name" label="Nome" required className="rounded-t-2xl" inputClass="rounded-t-2xl"/>
										<div className="flex double-input">
											<Inputs control={control} name="email" label="E-mail" required className="w-7/12"/>
											<Inputs control={control} mask="(99) 99999-9999" type="text" input="mask" name="phone"
											        label="Telefone" className="w-5/12"
											/>
										</div>
										<Inputs control={control} name="company" label="Empresa"/>
										<Inputs control={control} name="message" label="Mensagem" required input="textarea"
										        className="rounded-b-2xl" inputClass="rounded-b-2xl"/>

										<button
											disabled={!isValid}
											type="button"
											onClick={() => handleSubmit(onSubmit)()}
											className={clsx("bg-red-600 rounded-full mt-4 py-2 px-20 text-white font-semibold transition hover:bg-red-700", {
												"cursor-not-allowed opacity-50": !isValid
											})}
										>
											Enviar
										</button>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<FlashMessage/>
		</div>
	)
}

export default Contacts;