import React from 'react';
import {motion} from "framer-motion";

import {animateMotion} from "../utils/motion-animate";

const listTecnologies = [
	{
		title: "React & React Native",
		icon: "devicon-react-original",
	},
	{
		title: "NestJS",
		icon: "devicon-nestjs-original",
	},
	{
		title: "Tailwind",
		icon: "devicon-tailwindcss-original",
	},
	{
		title: "MySql",
		icon: "devicon-mysql-original",
	},
	{
		title: "Firebase",
		icon: "devicon-firebase-plain",
	},
	{
		title: "Google Cloud",
		icon: "devicon-google-original",
	},
	{
		title: "Amazon AWS",
		icon: "devicon-amazonwebservices-plain",
	},
	{
		title: "Microsoft Azure",
		icon: "devicon-azuredevops-plain",
	},
]

const Technologies = () => {
	return (
		<div
			className="mx-auto mt-36 lg:mt-48 rounded-4xl bg-neutral-950 py-12"
			id="technologies"
		>
			<div className="mx-auto max-w-7xl px-6 lg:px-8">
				<div className="mx-auto max-w-2xl lg:max-w-none">
					<div
						className="flex items-center gap-x-8"
						style={{
							opacity: 1,
							transform: "none",
						}}
					>
						<div className="flex-auto">
							<div className="flex items-center gap-x-8">
								<h2
									className="text-center font-display text-2xl font-semibold tracking-wider text-white sm:text-left">
									Algumas das tecnologias que utilizamos em nossos projetos
								</h2>
								<div className="h-px flex-auto bg-neutral-800"></div>
							</div>
							<div className="grid grid-cols-4 gap-8 lg:grid-cols-8 p-8 mt-8 items-center text-center">
								{listTecnologies.map((item, index) => (
									<motion.div
										key={index}
										initial={"hidden"}
										variants={animateMotion({
											direction: "vertical",
											distance: 40,
											delay: index * 0.2,
										})}
										whileInView={"show"}
										viewport={{once: true}}
									>
										<i
											data-tooltip-id="my-tooltip"
											data-tooltip-content={item.title}
											data-tooltip-place="bottom"
											className={`${item.icon} text-white text-5xl`}
										/>
									</motion.div>
								))}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Technologies;