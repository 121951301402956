import React from 'react';
import {scroll} from "../utils/configs";

const Footer = () => {
	return (
		<footer className="mx-auto max-w-7xl px-6 lg:px-8 mt-24 w-full sm:mt-32 lg:mt-40">
			<div className="mx-auto max-w-2xl lg:max-w-none">
				<div className="grid grid-cols-1 gap-x-8 gap-y-16 lg:grid-cols-2">
					<nav>
						<ul role="list" className="grid grid-cols-2 gap-8 sm:grid-cols-2">
							<li>
								<div className="font-display text-sm font-semibold tracking-wider text-neutral-950">Nossos links</div>
								<ul role="list" className="mt-4 text-sm text-neutral-700">
									<li className="mt-4"><a className="transition hover:text-neutral-950 cursor-pointer"
									                        onClick={() => scroll("#technologies")}>Tecnologias</a></li>
									<li className="mt-4"><a className="transition hover:text-neutral-950 cursor-pointer"
									                        onClick={() => scroll("#clients")}>Clientes</a>
									</li>
									<li className="mt-4"><a className="transition hover:text-neutral-950 cursor-pointer"
									                        onClick={() => scroll("#process")}>Processos</a>
									</li>
									<li className="mt-4"><a className="transition hover:text-neutral-950 cursor-pointer"
									                        onClick={() => scroll("#contact")}>Contato</a></li>
								</ul>
							</li>
							<li className="items-end content-end">
								<div className="text-sm justify-end text-right">
									O mundo está online e a sua empresa/seu produto?
									Desenvolvemos soluções personalizadas para empresas de todos os segmentos, mais do que clientes, temos
									parceiros de longa data.
									<div className="font-display text-sm font-semibold tracking-wider text-neutral-950 mt-4">Conte com a
										gente.</div>
								</div>

								<address className="text-sm not-italic text-neutral-600 text-right mt-1">
									<br/>
									São Paulo
									<br/>
									Tietê, 18530-000
								</address>
							</li>
						</ul>
					</nav>
				</div>
				<div
					className="mb-20 mt-24 flex flex-wrap items-end justify-between gap-x-6 gap-y-4 border-t border-neutral-950/10 pt-12">
					<a aria-label="Home" href="/public" className="rocket-logo-pos" title="Rocket IT"/>
					<p className="text-sm text-neutral-700">© Rocket IT. <span className="ml-4">2019</span></p></div>
			</div>
		</footer>
	)
}

export default Footer;