import React from 'react';
import InputMask from "react-input-mask";

interface InputMaskProps {
	mask: string | (string | RegExp)[];
	onChange: any;
	onBlur: any;
	value: string;
	name: string;
	type: string;
	className?: string;
}

const Mask = ({onChange, type, value, name, mask, className}: InputMaskProps) => {
	return (<InputMask
		onChange={onChange}
		value={value}
		mask={mask}
		type={type}
		id={name}
		name={name}
		className={`hover:ring-4 active:ring-4 ${className}`}
	/>)
}

export default Mask;