import { Variants } from "framer-motion";

export type directionList = "horizontal" | "vertical" | "none";

interface AnimateMotionProps {
    direction: directionList;
    distance?: number;
    delay?: number;
    duration?: number;
}
export const animateMotion = ({
                                  direction = "none",
                                  distance = 0,
                                  delay = 0,
                                  duration = 0.5,
                              }: AnimateMotionProps): Variants => {
    let hidden = {
        x: 0,
        y: 0,
        opacity: 0,
    }
    const distanceValue = distance ?? 0;
    if (direction !== "none") {
        hidden = {
            x: direction === 'horizontal' ? distanceValue  : 0,
            y: direction === 'vertical' ? distanceValue : 0,
            opacity: 0,
        }
    }
    return {
        hidden,
        show: {
            x: 0,
            y: 0,
            opacity: 1,
            transition: {
                type: "tween",
                duration,
                delay,
            },
        },
    };
}