import React, { createContext, useContext, useState } from 'react';
import {FlashMessageProps} from "../components/flash-message";

interface IConfigContext {
  menuIsOpen: boolean;
  toggleMenu: () => void;
  message: FlashMessageProps;
  toggleMessage: (data: FlashMessageProps) => void;
}

interface IConfigProps {
  children: React.ReactNode;
}

const ConfigContext = createContext<IConfigContext>({} as IConfigContext);

export const ConfigProvider = ({ children }: IConfigProps) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [message, setShowMessage] = useState<FlashMessageProps>({
    message: "",
    type: "success",
    time: 5000,
    show: false
  });

  const toggleMenu = () => {
    setMenuIsOpen(!menuIsOpen);
  };

  const toggleMessage = (data: FlashMessageProps) => {
    setShowMessage(data);
  }

  return (
    <ConfigContext.Provider value={{ menuIsOpen, toggleMenu, message, toggleMessage }}>
      {children}
    </ConfigContext.Provider>
  );
};

const useConfig = () => useContext(ConfigContext);

export default useConfig;