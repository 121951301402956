import React from 'react';
import {motion} from "framer-motion";
import {animateMotion} from "../utils/motion-animate";

const listProcess = [
	{
		title: "Planejamento da missão.",
		description: "Nesta fase inicial, definimos os objetivos do projeto, escopo, recursos necessários e cronograma. É o momento de alinhar as expectativas do cliente com nossas capacidades técnicas."
	},
	{
		title: "Engenharia e design.",
		description: "Aqui, nossa equipe de engenheiros e designers trabalha na criação do \"foguete\" - seja um site, aplicativo ou solução tecnológica. Focamos na arquitetura do sistema, design de interface e experiência do usuário."
	},
	{
		title: "Construção e desenvolvimento.",
		description: "Nesta etapa, transformamos os planos em realidade. Nossos desenvolvedores codificam, integram sistemas e constroem a solução, enquanto mantemos comunicação constante com o cliente."
	},
	{
		title: "Testes e Otimização.",
		description: "Antes do lançamento, realizamos testes rigorosos para garantir que tudo funcione perfeitamente. Ajustamos, otimizamos e nos certificamos de que o \"foguete\" está pronto para decolar."
	},
	{
		title: "Lançamento e suporte pós-voo.",
		description: "Finalmente, lançamos o projeto ao mundo! Mas nosso trabalho não termina aí. Oferecemos suporte contínuo, monitoramos o desempenho e estamos prontos para fazer ajustes conforme necessário."
	},
];

const Process = () => {
	return (
		<div className="w-full bg-process-color" id="process">
			<div className="mx-auto max-w-7xl px-6 lg:px-8 mt-32 md:mt-36">
				<div className="mx-auto max-w-2xl lg:max-w-none">
					<div className="max-w-3xl">
						<h2>
          <span
	          className="block font-display tracking-tight [text-wrap:balance] text-4xl font-medium sm:text-5xl text-neutral-950">
            Nosso <span className="px-2 bg-red-600 text-white">processo!</span>
          </span>
						</h2>
						<p className="mt-6 text-xl text-neutral-600">
							<strong>Evoluir no mercado de forma contínua</strong>, e se manter relevante diante ao cenário concorrente
							requer uma equipe de especialistas criando estratégias e tecnologias a seu favor, com <strong>suporte
							necessário a todo instante</strong>.
						</p>
					</div>
				</div>
				<div className="grid grid-cols-1 gap-2 lg:grid-cols-2 lg:items-center lg:justify-end mt-1 sm:mt-16">
					<div className="flex w-full items-end">
						<div className="bg-process mb-2"></div>
					</div>
					<div className="flex-auto w-full pb-8 items-start">
						<ul className="w-full separator text-base text-neutral-600 lg:w-1/2 lg:pl-4">
							{listProcess.map((item, index) => (
								<motion.li
									key={index}
									initial={"hidden"}
									variants={animateMotion({
										direction: "horizontal",
										distance: 40,
										delay: index * 0.3,
									})}
									whileInView={"show"}
									viewport={{once: true}}
									className="py-6">
									<strong className="font-semibold text-neutral-950">{item.title} </strong>
									{item.description}
								</motion.li>
							))}
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Process;