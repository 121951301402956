import React, { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { shuffleArrays } from "../utils/configs";

const testimonialList = [
  {
    text: "A Rocket IT liderou com maestria a reestruturação do site da Confederação Brasileira de Kungfu Wushu, um portal complexo de alta visitação que atende praticantes, lideranças estaduais e governo federal. Com resiliência e determinação, entregaram um projeto que cumpre exigências específicas e promove interação eficaz. Recomendo fortemente os serviços para projetos desafiadores.",
    name: "Rafael Uliani",
    company: "CBKW",
    position: "Presidente 2019 a 2023",
  },
  {
    text: "A Rocket superou minhas expectativas com seu profissionalismo e compreensão das necessidades da minha loja. Criaram um site moderno, fácil de navegar e alinhado com meu negócio, além de oferecerem excelente suporte contínuo. Recomendo fortemente para quem busca um parceiro de tecnologia confiável e de qualidade.",
    name: "Mailson Pignata",
    company: "Ares Produções",
    position: "Diretor",
  },
];

let timeoutTestimonial: any = null;
const timeTransition = 8000;

const Testimonials = () => {
  const [index, setIndex] = useState(0);
  const [list, setList] = useState(testimonialList);

  const nextTestimonial = (value = 0) => {
    clearTimeout(timeoutTestimonial);
    let nextIndex = value + 1;
    if (nextIndex >= list.length) nextIndex = 0;
    setIndex(nextIndex);
    timeoutTestimonial = setTimeout(() => {
      nextTestimonial(nextIndex);
    }, timeTransition);
  };

  useEffect(() => {
    setList(shuffleArrays(testimonialList));
    handleStartTestimonial();
  }, []);

  const handleStartTestimonial = () => {
    clearTimeout(timeoutTestimonial);
    timeoutTestimonial = setTimeout(() => {
      nextTestimonial();
    }, timeTransition);
  }

  const handleMouseEnter = () => {
    clearTimeout(timeoutTestimonial);
  };

  const handleMouseLeave = () => {
    clearTimeout(timeoutTestimonial);
    timeoutTestimonial = setTimeout(() => {
      nextTestimonial(index);
    }, timeTransition / 2);
  };

  const handleMouseClick = (value: number) => {
    clearTimeout(timeoutTestimonial);
    setIndex(value);
    timeoutTestimonial = setTimeout(() => {
      nextTestimonial(value);
    }, timeTransition);
  }

  return (
    <div
      className="relative isolate bg-neutral-100 py-16 sm:py-24 md:py-36"
      id="clients"
    >
      <div className="absolute inset-0 testimonials bg-blend-color-burn opacity-60 -z-10 blur-sm"></div>
      <div className="container mx-auto max-w-7xl px-6 lg:px-8 testimonials-list content-center">
        <div className="mx-auto max-w-2xl lg:max-w-none">
          <figure
            className="mx-auto max-w-4xl grid grid-cols-1"
            onMouseOver={handleMouseEnter}
            onMouseOut={handleMouseLeave}
          >
            <AnimatePresence>
              <motion.div
                key={index}
                variants={{
                  left: {
                    opacity: 0,
                    x: -40,
                    y: 0,
                    position: "absolute",
                    transition: { duration: 0.4 },
                  },
                  right: { opacity: 1, x: 0, y: 0, position: "relative" },
                }}
                transition={{ duration: 0.5 }}
                initial={"left"}
                animate={"right"}
                exit={"left"}
                className="flex-auto transition-slow"
              >
                <blockquote className="relative font-display font-medium tracking-tight text-neutral-700 text-2xl">
                  <p className="before:content-['“'] after:content-['”'] sm:before:absolute sm:before:right-full">
                    {list[index].text}
                  </p>
                </blockquote>
                <p className="mt-4 w-full text-left text-neutral-500">
                  {list[index].name}, {list[index].company} -{" "}
                  {list[index].position}
                </p>
              </motion.div>
            </AnimatePresence>
          </figure>
        </div>
        <div className="flex justify-center testimonials-dots">
          {list.map((item, idx) => (
            <button
              key={idx}
              onClick={() => handleMouseClick(idx)}
              className={`w-4 h-4 mx-2 rounded-full ${
                idx === index ? "actived" : ""
              }`}
            ></button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
