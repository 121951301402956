import React from 'react';
import {motion} from "framer-motion";
import {animateMotion, directionList} from "../utils/motion-animate";
import {Icon} from "../components";

interface aboutProps {
	icon: string;
	title: string;
	direction: directionList;
	distance: number;
	description: string;
}

const aboutList: aboutProps[] = [
	{
		icon: "design_services",
		title: "UI / UX",
		direction: "horizontal",
		distance: -40,
		description: "Decolamos sua marca com interfaces intuitivas e experiências de usuário cativantes. Nosso design não é apenas bonito, é estrategicamente elaborado para converter visitantes em clientes fiéis.",
	},
	{
		icon: "code3",
		title: "Desenvolvimento",
		direction: "vertical",
		distance: 40,
		description: "Construímos os foguetes que impulsionam seu negócio online. De sites impressionantes a aplicativos inovadores, criamos soluções sob medida que funcionam perfeitamente em qualquer dispositivo, online ou offline.",
	},
	{
		icon: "analytics",
		title: "Analítico",
		direction: "vertical",
		distance: -40,
		description: "Navegamos pelo universo dos seus dados, transformando-os em insights acionáveis. Nossa equipe de especialistas analisa, interpreta e implementa soluções tecnológicas que otimizam sua infraestrutura e impulsionam o crescimento.",
	},
	{
		icon: "storage",
		title: "Cloud & IA",
		direction: "horizontal",
		distance: 40,
		description: "Elevamos seu negócio às nuvens com soluções cloud robustas e seguras. Integramos inteligência artificial para automatizar processos, melhorar o atendimento ao cliente e turbinar suas vendas, levando sua operação a outro nível.",
	},
];

const About = () => {
	return (
		<>
			<div className="mx-auto max-w-7xl px-6 lg:px-8 mt-24 md:mt-36" id="about">
				<div className="mx-auto max-w-2xl lg:max-w-none">
					<div className="max-w-3xl">
						<h2>
              <span
	              className="block font-display tracking-tight [text-wrap:balance] text-4xl font-medium sm:text-5xl text-neutral-950">
	              O jeito <span className="px-2 bg-red-600 text-white">rocket</span> de ser
              </span>
						</h2>
						<p className="mt-6 text-xl text-neutral-600">
							<strong>Evoluir no mercado de forma contínua</strong>, e se manter relevante diante ao cenário concorrente
							requer uma
							equipe de especialistas criando estratégias e tecnologias a seu favor, com <strong>suporte necessário a
							todo
							instante</strong>.
						</p>
					</div>
				</div>
			</div>
			<div className="mx-auto max-w-7xl px-6 lg:px-8 mt-16 pb-36">
				<div className="mx-auto max-w-2xl lg:max-w-none">
					<div className="grid grid-cols-1 gap-8 lg:grid-cols-4">
						{aboutList.map((item, index) => (
							<motion.div
								key={index}
								className="flex"
								initial={"hidden"}
								variants={animateMotion({
									direction: item.direction,
									distance: item.distance,
									delay: 0.4,
								})}
								whileInView={"show"}
								viewport={{once: true}}
							>
								<article
									className="transition-slow flex-auto bg-transparent p-8 rounded-4xl border-2 border-neutral-300
									hover:bg-white drop-shadow-md hover:drop-shadow-lg z-40 hover:w-[17.5rem]">
									<h3>
                  <span className="rounded-full bg-neutral-900 py-6 px-4">
                    <Icon
	                    icon={item.icon}
	                    size={36}
	                    className="fill-white group-hover:fill-neutral-50 transition"
                    />
                  </span>
									</h3>
									<h3 className="mt-6 font-display text-2xl font-semibold text-neutral-950">
										{item.title}
									</h3>
									<p className="mt-4 text-neutral-600">
										{item.description}
									</p>
								</article>
							</motion.div>
						))}
					</div>

					<p className="mt-6 text-xl text-neutral-600 mt-12">
						Na Rocket IT, nossa missão é clara: <strong>impulsionar o seu sucesso</strong>. Adaptamos nossas soluções às suas
						necessidades específicas, garantindo que cada projeto seja tão único quanto o seu negócio. <strong>Prepare-se
						para decolar!</strong>.
					</p>
				</div>
			</div>
		</>
	);
};

export default About;