import React from 'react';
import clsx from "clsx";
import {Icon} from "../components";
import useConfig from "../context/config-context";
import {scroll} from "../utils/configs";

const Menu = () => {
	const {toggleMenu, menuIsOpen} = useConfig();

	return (
		<div
			className={clsx(
				"relative z-50 overflow-hidden bg-neutral-950 pt-4 transition-all",
				{
					"h-0": !menuIsOpen,
					"h-300 sm:h-200": menuIsOpen,
				}
			)}
			style={{
				transform: "none",
				transformOrigin: "50% 50% 0px",
				transitionDuration: "1s",
			}}
		>
			<div className="bg-neutral-950 pb-14 pt-36">
				<div className="absolute left-0 right-0 top-2 z-40 pt-14">
					<div className="mx-auto max-w-7xl px-6 lg:px-8">
						<div className="mx-auto max-w-2xl lg:max-w-none">
							<div className="flex items-center justify-between">
								<a aria-label="Home" href="/public" className="rocket-logo-neg" title="Rocket IT"/>
								<div className="flex items-center gap-x-8">
									<a
										className="rounded-full px-4 py-2 text-sm font-semibold transition bg-white text-neutral-950 hover:bg-neutral-200 cursor-pointer"
										onClick={() => scroll("#contact")}
									>
										<span className="relative">Contatos</span>
									</a>
									<button
										type="button"
										aria-expanded="true"
										aria-controls=":R3a:"
										className="group -m-2.5 rounded-full p-2.5 transition hover:bg-white/10"
										aria-label="Toggle navigation"
										onClick={toggleMenu}
									>
										<svg
											viewBox="0 0 24 24"
											aria-hidden="true"
											className="h-6 w-6 fill-white group-hover:fill-red-600 transition"
										>
											<path d="m5.636 4.223 14.142 14.142-1.414 1.414L4.222 5.637z"></path>
											<path d="M4.222 18.363 18.364 4.22l1.414 1.414L5.636 19.777z"></path>
										</svg>
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<nav className="mt-px font-display text-5xl font-medium tracking-tight text-white">
					<div className="even:mt-px sm:bg-neutral-950 border-t border-b border-neutral-800">
						<div className="mx-auto max-w-7xl px-6 lg:px-8">
							<div className="mx-auto max-w-2xl lg:max-w-none">
								<div className="grid grid-cols-1 sm:grid-cols-2">
									<a
										className="group relative isolate -mx-6 bg-neutral-950 px-6 py-10 even:mt-px sm:mx-0 sm:px-0 sm:py-16 sm:odd:pr-16 sm:even:mt-0  sm:even:border-l sm:even:border-neutral-800 sm:even:pl-16 border-b border-neutral-800 sm:border-b-0 hover:text-red-500 transition-all cursor-pointer"
										onClick={() => scroll("#technologies")}
									>
										Tecnologias
										<span
											className="absolute inset-y-0 -z-10 w-screen bg-neutral-900 opacity-0 transition group-odd:right-0 group-even:left-0 group-hover:opacity-100"></span>
									</a>
									<a
										className="group relative isolate -mx-6 bg-neutral-950 px-6 py-10 even:mt-px sm:mx-0 sm:px-0 sm:py-16 sm:odd:pr-16 sm:even:mt-0 sm:even:border-l sm:even:border-neutral-800 sm:even:pl-16 hover:text-red-500 transition-all cursor-pointer"
										onClick={() => scroll("#clients")}
									>
										Clientes
										<span
											className="absolute inset-y-0 -z-10 w-screen bg-neutral-900 opacity-0 transition group-odd:right-0 group-even:left-0 group-hover:opacity-100"></span>
									</a>
								</div>
							</div>
						</div>
					</div>
					<div className="even:mt-px sm:bg-neutral-950 border-b border-neutral-800">
						<div className="mx-auto max-w-7xl px-6 lg:px-8">
							<div className="mx-auto max-w-2xl lg:max-w-none">
								<div className="grid grid-cols-1 sm:grid-cols-2">
									<a
										className="group relative isolate -mx-6 bg-neutral-950 px-6 py-10 even:mt-px sm:mx-0 sm:px-0 sm:py-16 sm:odd:pr-16 sm:even:mt-0 sm:even:border-l sm:even:border-neutral-800 sm:even:pl-16 hover:text-red-500 transition-all cursor-pointer border-b border-neutral-800 sm:border-b-0"
										onClick={() => scroll("#process")}
									>
										Nosso Processo
										<span
											className="absolute inset-y-0 -z-10 w-screen bg-neutral-900 opacity-0 transition group-odd:right-0 group-even:left-0 group-hover:opacity-100"></span>
									</a>
									<a
										className="group relative isolate -mx-6 bg-neutral-950 px-6 py-10 even:mt-px sm:mx-0 sm:px-0 sm:py-16 sm:odd:pr-16 sm:even:mt-0 sm:even:border-l sm:even:border-neutral-800 sm:even:pl-16 hover:text-red-500 transition-all cursor-pointer"
										onClick={() => scroll("#contact")}
									>
										Contato
										<span
											className="absolute inset-y-0 -z-10 w-screen bg-neutral-900 opacity-0 transition group-odd:right-0 group-even:left-0 group-hover:opacity-100"></span>
									</a>
								</div>
							</div>
						</div>
					</div>
				</nav>
				<div className="mx-auto max-w-7xl px-6 lg:px-8">
					<div className="mx-auto max-w-2xl lg:max-w-none">
						<div className="grid grid-cols-1 gap-y-10 pb-16 pt-10 sm:grid-cols-2 sm:pt-16">
							<div>
								<ul className="mt-6 grid grid-cols-1 gap-8 sm:grid-cols-2">
									<li>
										<address className="text-sm not-italic text-neutral-300">
											<strong className="text-white">Brasil</strong>
											<br/>
											São Paulo
											<br/>
											Tietê, 18530-000
										</address>
									</li>
								</ul>
							</div>
							<div className="sm:border-l sm:border-transparent sm:pl-16">
								<h2 className="font-display text-base font-semibold text-white">
									Nossas redes
								</h2>
								<ul className="flex gap-x-10 text-white mt-6">
									<li>
										<a
											aria-label="Facebook"
											className="transition hover:text-neutral-200"
											href="https://www.linkedin.com/company/69529664"
											target="_blank"
										>
											<Icon
												icon="linkedin"
												size={24}
												className="fill-neutral-200"
											/>
										</a>
									</li>
									<li>
										<a
											aria-label="Instagram"
											className="transition hover:text-neutral-200"
											href="https://www.instagram.com/rkt.it"
											target="_blank"
										>
											<Icon
												icon="instagram"
												size={24}
												className="fill-neutral-200"
											/>
										</a>
									</li>
									<li>
										<a
											aria-label="GitHub"
											className="transition hover:text-neutral-200"
											href="https://github.com/rktit"
											target="_blank"
										>
											<Icon
												icon="github"
												size={24}
												className="fill-neutral-200"
											/>
										</a>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Menu;