import React from 'react';
import ReactDOM from 'react-dom/client';

import './ui/style.css';
import './ui/global.css';
import 'devicon/devicon.min.css';

import router from './routes';
import reportWebVitals from './reportWebVitals';
import {RouterProvider} from "react-router-dom";
import {ConfigProvider} from "./context/config-context";

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);

root.render(
	<React.StrictMode>
		<ConfigProvider>
			<RouterProvider router={router}/>
		</ConfigProvider>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
