import React from 'react';

interface TextareaProps {
	onChange: any;
	onBlur: any;
	value: string;
	name: string;
	rows?: number;
	className?: string;
}

const TextArea = ({onChange, onBlur, name, value, rows = 4, className}: TextareaProps) => {
	return (<textarea
		id={name}
		name={name}
		onChange={onChange}
		onBlur={onBlur}
		value={value}
		className={`hover:ring-4 active:ring-4 ${className}`}
		rows={rows}
	/>)
}

export default TextArea;